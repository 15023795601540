/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
/* Importing Bootstrap SCSS file. */
@import "ngx-toastr/toastr";

.ngx-toastr {
  background-color: #1d2132 !important;
  font-size: 14px !important;
  border-radius: 20px !important;
}

.ngx-toastr .toast-title {
  font-style: italic !important;
}

#toast-container > .toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjU0MSA4SDEyLjU1MU0xMS41NDEgMTJIMTIuNTQxVjE2SDEzLjU0MU0yMS41NDEgMTJDMjEuNTQxIDE2Ljk3MDYgMTcuNTExNiAyMSAxMi41NDEgMjFDNy41NzA0NSAyMSAzLjU0MTAyIDE2Ljk3MDYgMy41NDEwMiAxMkMzLjU0MTAyIDcuMDI5NDQgNy41NzA0NSAzIDEyLjU0MSAzQzE3LjUxMTYgMyAyMS41NDEgNy4wMjk0NCAyMS41NDEgMTJaIiBzdHJva2U9IiMwMEM3QjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

#toast-container > .toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjQ5MDggNy4wNTAzM0w3LjU5MTI3IDE2Ljk0OThNNy41OTEyNyA3LjA1MDMzTDE3LjQ5MDggMTYuOTQ5OCIgc3Ryb2tlPSIjRkYyRTAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNTQxMDIgMTJMMTAuNTQxIDE3TDIwLjU0MSA3IiBzdHJva2U9InVybCgjcGFpbnQwX2xpbmVhcl8zNzI5Xzg2MikiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzM3MjlfODYyIiB4MT0iNS41NDEwMiIgeTE9IjE1Ljc5MzEiIHgyPSI5LjQ4ODI0IiB5Mj0iMTAuNjM3OCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMDc4MUZFIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwQzdCNCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
}

#toast-container > .toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjU0MSA4SDEyLjU1MU0xMS41NDEgMTJIMTIuNTQxVjE2SDEzLjU0MU0yMS41NDEgMTJDMjEuNTQxIDE2Ljk3MDYgMTcuNTExNiAyMSAxMi41NDEgMjFDNy41NzA0NSAyMSAzLjU0MTAyIDE2Ljk3MDYgMy41NDEwMiAxMkMzLjU0MTAyIDcuMDI5NDQgNy41NzA0NSAzIDEyLjU0MSAzQzE3LjUxMTYgMyAyMS41NDEgNy4wMjk0NCAyMS41NDEgMTJaIiBzdHJva2U9IiMwMEM3QjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

html body.swal2-height-auto {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

:focus-visible {
  outline: none;
}

.modal {
  z-index: 99999;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-sm {
  font-size: 0.925rem;
}

.font-size-xs {
  font-size: 0.8rem;
}

.font-size-lg {
  font-size: 1.08rem;
}

.font-size-h1 {
  font-size: 2rem !important;
}

.font-size-h2 {
  font-size: 1.75rem !important;
}

.font-size-h3 {
  font-size: 1.5rem !important;
}

.font-size-h4 {
  font-size: 1.35rem !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.font-size-h6 {
  font-size: 1.175rem !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.zindex-0 {
  z-index: 0;
}

.zindex-n1 {
  z-index: -1;
}

.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.cdk-overlay-container {
  z-index: 100000 !important; //lower then fixed header z-index so it goes behind it
}

.language-dropdown-open .cdk-overlay-container {
  z-index: 1000000 !important;
}

.scrollbar {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #1a1e32;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

#wrapper-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  background-color: #11131c;
}

#wrapper-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #5e5c5c;
}

#wrapper-scroll::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: var(--white, #fff);
  width: 6px;
}

.custom-tooltip {
  border: 8px solid transparent !important;
  opacity: 1 !important;

  .tooltip-inner {
    font-family: Poppins, sans-serif;
    padding: 12px;
    color: #9d9ea2;
    word-wrap: break-word;
    text-align: left;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 16px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    background: rgba(21, 24, 40, 0.8);
    border: 1px solid hsla(0, 0%, 77%, 0.06);
    border-radius: 10px;

    > div {
      user-select: auto;
    }

    span {
      color: #fff;
    }
  }

  .tooltip-arrow {
    &:before {
      border-top-color: rgba(97, 97, 97, 0.9);
    }
  }
}

.faq_accordion_mbl {
  button {
    background-color: transparent;
    border: 0;
    color: #fff;
  }

  .accordion-item {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #1d1f2a;

    .accordion-header {
      .accordion-button {
        padding: 0;
        padding-top: 22px;
        padding-bottom: 22px;
        background-color: transparent;
        box-shadow: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: 130%;
        transition: 0.3s;

        &::after {
          background: url("./assets/images/chevron-right.svg") center/cover no-repeat;
          width: 14px;
          height: 14px;
          content: "";
          transform: none;
        }

        &:not(.collapsed, .accordion-button-secondary) {
          color: #4f5056;

          &::after {
            background: url("./assets/images/chevron-right-dark.svg") center/cover no-repeat;
            transform: rotate(-180deg);
          }
        }
      }
    }

    .accordion-body {
      padding: 0;

      > .faq_accordion {
        margin-top: 0;
      }
    }
  }
}

span {
  &.highlight {
    background: #00c7b4 !important;
  }
}

.faq_accordion {
  margin-top: 20px;
  display: block;

  button {
    background-color: transparent;
    border: 0;
    color: #fff;
  }

  .accordion-item {
    background: rgba(21, 24, 40, 0.8);
    border: 1px solid hsla(0, 0%, 100%, 0.06);
    border-radius: 15px;
    cursor: pointer;
    padding: 20px 20px 24px;
    position: relative;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .accordion-header {
      .accordion-button {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: 130%;

        @media (max-width: 990px) {
          display: block;
          position: relative;
          padding-right: 10%;
        }

        span {
          &.highlight {
            background: #00c7b4;
          }
        }

        &::after {
          background: url("./assets/images/btn_minus.png") center/cover no-repeat;
          width: 30px;
          height: 30px;
          content: "";
          transform: none;

          @media (max-width: 990px) {
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        &.collapsed {
          &::after {
            content: "";
            background: url("./assets/images/btn_plus.png") center/cover no-repeat;
            width: 30px;
            height: 30px;
            transform: none;
          }
        }
      }
    }

    .Item_answer {
      color: #a1a3a9;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.01em;
      line-height: 150%;
      margin-top: 20px;

      a {
        color: #02a4c7;
        text-decoration: none;
      }
    }

    .accordion-body {
      padding: 0;
    }
  }
}

.menu-accordion {
  .accordion-item {
    background-color: transparent;
    border: 0;
    border-radius: 0;

    .accordion-body {
      padding: 12px 16px;
    }

    .menu-accordion__toggle {
      padding: 0;
      background-color: transparent;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.02em;
      line-height: 27px;
      box-shadow: none;
      border: 0;
      color: #fff;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        background: url("./assets/images/chevron-right.svg") center/cover no-repeat;
        width: 12px;
        height: 12px;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: transform 0.2s ease-in-out;
      }

      &:not(.collapsed) {
        span {
          background: linear-gradient(34.49deg, #0781fe -12.58%, #00c7b4 102.84%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }

        &::after {
          background: url("./assets/images/chevron-down.svg") center/cover no-repeat;
          transform: translateY(-50%) rotate(0);
        }
      }
    }
  }
}

.topics_list {
  margin-top: 24px;

  .topics_list_item {
    &:not(:first-child) {
      margin-top: 6px;
    }

    span {
      color: #ccc;
      font-weight: 600;

      &.highlight {
        background: #00c7b4;
      }
    }
  }
}

.topics_text {
  &:not(:first-child) {
    margin-top: 24px;
  }

  span {
    color: #ccc;
    font-weight: 600;
  }

  .topics_text_title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.Custom_backdrop {
  background-color: #0b0c12;
  z-index: 99999 !important;
}

@media (min-width: 1440px) {
  .faq_accordion {
    .accordion-item {
      &:not(:first-child) {
        margin-top: 20px;
      }

      .accordion-header {
        .accordion-button {
          font-size: 18px;
        }
      }

      .Item_answer {
        width: 555px;
      }
    }
  }
}

.toverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.swal2-actions {
  width: 100%;
  padding: 0 20px;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  border-radius: 20px;
  background: #11131c;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 16px;
  letter-spacing: -0.48px;
  color: #fff;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 24em;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  backdrop-filter: blur(3px);
}

div:where(.swal2-container) .swal2-html-container {
  color: #727581;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.28px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-image: linear-gradient(44deg, #0072e7 0%, #00aa9a 100%);
  border: 0;
  padding: 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 42px;
  border-radius: 12px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.03em;
  color: #fff;
  margin-bottom: 0;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:hover {
  background-image: linear-gradient(44deg, #0072e7 0%, #00aa9a 100%) !important;
  box-shadow:
    -12px 0px 25px 12px rgba(0, 114, 231, 0.15),
    12px 0px 25px 12px rgba(0, 170, 154, 0.15);
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.03em;
  color: #fff;
}

.title-highlight {
  background: linear-gradient(34.49deg, #0781fe -12.58%, #00c7b4 102.84%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

swiper-container {
  --swiper-theme-color: #0781fe;
  --swiper-scrollbar-drag-bg-color: #fff;
  --swiper-scrollbar-bg-color: #1a1e32;
  --swiper-scrollbar-border-radius: 30px;
  --swiper-scrollbar-size: 6px;
  --swiper-scrollbar-bottom: 0;
  --swiper-scrollbar-sides-offset: 15px;
  --swiper-wrapper-scrollbar-size: 6px;
}

:root {
  // Switch
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 16px;
  --mat-switch-pressed-handle-size: 16px;
  --mdc-switch-selected-handle-color: #fff;
  --mat-switch-with-icon-handle-size: 16px;
  --mdc-switch-unselected-track-color: #1e2232;
  --mat-switch-track-outline-color: #2e3143;
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-shape: 12px;
  --mdc-switch-unselected-focus-track-color: #1e2232;
  --mdc-switch-unselected-hover-track-color: #1e2232;
  --mdc-switch-selected-pressed-track-color: #1e2232;
  // Menu
  --mat-select-panel-background-color: #1e2232;
}
